import i18next from 'i18next';

// Initialize i18next first
i18next.init({
    supportedLngs: ["de", "en"],
    lng: 'de',
    fallbackLng: 'en',
    resources: {
        de: {
            translation: {
            }
        },
        en: {
            translation: {
            }
        }
    }
});

// Make it globally available immediately
window.i18next = i18next;

// Then import jQuery and make it global
import "core-js/stable"
import "regenerator-runtime/runtime"
global.jQuery = require("jquery")

// Now jQuery UI and other jQuery-dependent imports
import "jquery-ui"
import "jquery-contextmenu"

// Set jQuery globals after import
const $ = jQuery
window.$ = $
window.jQuery = $

/* include Sentry */
import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"

global.Sentry = Sentry
global.SentryIntegrations = Integrations

// CSS imports
import "font-awesome/css/font-awesome.min.css"
import "sweetalert2/dist/sweetalert2.css"

/* Include jQuery UI components selectively to keep the JS small */
require("jquery-ui/ui/widgets/autocomplete")
require("jquery-ui/ui/widgets/draggable")
require("jquery-ui/ui/widgets/resizable")
require("jquery-ui/ui/widgets/accordion")
require("jquery-ui/ui/widgets/button")
require("jquery-ui/ui/widgets/menu")

// jQuery UI CSS imports
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/autocomplete.css';
import 'jquery-ui/themes/base/draggable.css';
import 'jquery-ui/themes/base/resizable.css';
import 'jquery-ui/themes/base/accordion.css';
import 'jquery-ui/themes/base/button.css';
import 'jquery-ui/themes/base/menu.css';

// PDF related imports
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";

// Set up pdfMake with fonts
pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts.vfs;
window.pdfMake = pdfMake;
window.JSZip = require('jszip')

//for reports module
import 'bootstrap-daterangepicker/daterangepicker.css';
require('bootstrap-daterangepicker/daterangepicker');

window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.min.js')

// Core DataTables
import DataTable from 'datatables.net-bs5';
import 'datatables.net-bs5/css/dataTables.bootstrap5.css';

// ColReorder extension
import 'datatables.net-colreorder-bs5';
import 'datatables.net-colreorder-bs5/css/colReorder.bootstrap5.css';

// Select extension
import 'datatables.net-select-bs5';
import 'datatables.net-select-bs5/css/select.bootstrap5.css';

// Buttons extension and its features
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.css';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-buttons/js/buttons.html5.js';

// Responsive extension
import 'datatables.net-responsive-bs5';
import 'datatables.net-responsive-bs5/css/responsive.bootstrap5.css';

// TinyMCE dependencies and configuration
require('./components/tinymce');

console.log("JS: Header libraries loaded.")
