/*
 TinyMCE dependencies and configuration
 */

// Import TinyMCE
import 'tinymce';

// Theme and Icons
import 'tinymce/themes/silver';
import 'tinymce/icons/default';

// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// Model
import 'tinymce/models/dom/model';

// Plugins - Update the import paths
import 'tinymce/plugins/code';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/code';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/media';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/table';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/help';
import 'tinymce/plugins/autoresize';

// Global configurations
window.tinymceplugins = 'autoresize preview importcss searchreplace autolink directionality code visualblocks help fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount charmap quickbars';
window.tinymcetoolbar = 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap | fullscreen preview print | insertfile image media link code visualblocks help | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol';
window.tinymcedefaultcontentstyle = "body { font-size: 12pt; font-family: Arial; }";
window.tinymceeditorheight = 220;
window.tinymcemenubar = false;
window.tinymcetoolbarmode = "sliding";
window.tinymcebranding = false;
window.tinymcelanguage = 'de';
window.tinymceimagetitle = true;
window.tinymceautomaticuploads = true;
